import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from '@mui/material';
import PageLayout from '../components/pageLayout';
import Markdown from '../components/markdown';
import theme from '../gatsby-theme-material-ui-top-layout/theme';
import { genHelmet } from '../actions/baseActions';

const styles = {
  root: {
    paddingBottom: theme.spacing(4),
    '& > div': {
      padding: {
        xs: `${theme.spacing(4)} 0`,
        lg: theme.spacing(4),
      },
    },
  },
  alignVertical: {
    display: 'flex',
    alignItems: 'center',
  },
};

const Profile = (props) => {
  const pageData = props.data.contentfulPage;
  const { contentCollection } = pageData;

  return (
    <React.Fragment>
      {genHelmet(pageData, {
        type: 'page',
        // siteData: pageData,
        path: props.path,
      })}

      {/* --- LAYOUT --- */}
      <PageLayout location={props.location}>
        <Grid layout="row" container sx={styles.root}>
          {contentCollection.map((entry, index) => (
            <Grid
              key={`gridItem-${index}`}
              item
              xs={entry.xs}
              sm={entry.sm}
              md={entry.md}
              lg={entry.lg}
              xl={entry.xl}
              sx={{
                ...(entry.alignVertical && styles.alignVertical),
              }}
            >
              <Markdown
                markdown={entry.markdown}
                newLinesAsBreaks={true}
                suppressLinkUnderlines={true}
              />
            </Grid>
          ))}
        </Grid>
      </PageLayout>
    </React.Fragment>
  );
};

export default Profile;

export const pageQuery = graphql`
  {
    contentfulPage(slug: { eq: "profile" }) {
      title
      slug
      headline
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
      contentCollection {
        md
        sm
        xs
        lg
        xl
        align
        alignVertical
        markdown {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
